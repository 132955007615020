import { useStaticQuery, graphql } from 'gatsby'
import dedupe from 'dedupe'

export const usePolicyPages = () => {
  const { allStrapiPolicy } = useStaticQuery(policiesQuery)
  return allStrapiPolicy.nodes
    ? dedupe(allStrapiPolicy.nodes, node => node.slug)
    : null
}


const policiesQuery = graphql`
  query allStrapiPolicyQuery($language: String) {
    allStrapiPolicy(filter: {  locale: { eq: $language } }) {
      nodes {
        title
        slug
        pages {
          body {
            data
          }
          publishedAt
        }
      }
    }
  }
`
